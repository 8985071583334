<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ name: 'news' }">资讯管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          infoForm.id ? "编辑资讯" : "添加资讯"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
        <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-form
          ref="infoForm"
          :rules="infoRules"
          :model="infoForm"
          label-width="120px"
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="infoForm.title"></el-input>
          </el-form-item>

          <el-form-item label="作者" prop="author">
            <el-input v-model="infoForm.author"></el-input>
          </el-form-item>

          <el-form-item
            label="资讯主图"
            prop="list_pic_url"
            v-if="infoForm.list_pic_url"
            class="image-uploader-diy new-height"
          >
            <img
              v-if="infoForm.list_pic_url"
              :src="infoForm.list_pic_url+'=watershark'"
              class="image-show"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="delePicList"
            >
            </el-button>
            <i class="fa fa-trash-o"></i>
          </el-form-item>

          <el-form-item
            label="资讯主图"
            prop="list_pic_url"
            v-if="!infoForm.list_pic_url"
          >
            <el-upload
              name="file"
              class="upload-demo"
              :action="qiniuZone"
              :on-success="handleUploadListSuccess"
              :before-upload="getQiniuToken"
              list-type="picture-card"
              :data="picData"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                建议尺寸750*300，不超过2M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="商品详情" prop="content">
            <div class="edit_container">
              <quill-editor
                v-model="infoForm.content"
                ref="myTextEditor"
                class="editer"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @ready="onEditorReady($event)"
              >
              </quill-editor>
            </div>
          </el-form-item>
          <!-- 图片上传组件辅助-->
          <el-form-item class="upload_ad">
            <el-upload
              name="file"
              class="avatar-uploader"
              :action="qiniuZone"
              list-type="picture-card"
              :file-list="detail_list"
              :before-upload="beforeUpload"
              :on-success="handleUploadDetailSuccess"
              :on-preview="handlePreview"
              :data="picData"
            >
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
            <el-button @click="goBackPage">返回列表</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" size="tiny">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import api from "@/config/api";
import $ from "jquery";
import { quillEditor } from "vue-quill-editor";
import ElForm from "element-ui/packages/form/src/form.vue";
import ElFormItem from "element-ui/packages/form/src/form-item.vue"; //调用富文本编辑器
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["clean"],
  ["link", "image", "video"],
];
export default {
  name: "GoodsAddPage",
  data() {
    return {
      root: "",
      qiniuZone: "",
      picData: {
        token: "",
      },
      url: "",
      detail_list: [],
      dialogImageUrl: "",
      dialogVisible: false,
      options: [],
      uploaderHeader: {
        "I-WanBo-Token": localStorage.getItem("token") || "",
      },
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      category: [],
      infoForm: {
        title: "",
        list_pic_url: "",
        author:'',
        content: "",
      },
      infoRules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
      visible: false,
      hasPost: 0,
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: "",
      },
    };
  },
  methods: {
    handleUploadcetificateSuccess(res, file) {
      let url = this.url;
      this.infoForm.certificate = url + res.key;
    },

    selectBlur(e) {
      // this.searchForm.fileExt = e.target.value
    },

    hasErrorAct(err) {
      console.log(err);
    },
    getQiniuToken() {
      let that = this;
      this.axios.post("index/getQiniuToken").then((response) => {
        let resInfo = response.data.data;
        console.log(resInfo.token);
        that.picData.token = resInfo.token;
        that.url = resInfo.url;
      });
    },
    //视频上传部分

    specChange(value) {
      this.specForm.id = value;
    },
    addPrimarySpec() {
      this.is_has_spec = true;
    },
    getImgUrl() {
      let str = this.infoForm.content;
      //匹配图片（g表示匹配所有结果i表示区分大小写）
      let imgReg = /<img [^>]*src=['"]([^'"]+)[^>]*>/gi;
      //匹配src属性
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      let arr = str.match(imgReg);
      if (arr == null) {
        return false;
      }
      let data = [];

      for (let i = 0; i < arr.length; i++) {
        let src = arr[i].match(srcReg);
        let jsonData = {};
        jsonData.url = src[1];
        data[i] = jsonData;
      }
      this.detail_list = data;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },

    delePicList() {
      this.infoForm.list_pic_url = "";
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    onEditorReady(editor) {
      console.log("editor ready!", editor);
    },
    onEditorFocus(editor) {
      console.log("editor focus!", editor);
    },
    onEditorBlur(editor) {
      console.log("editor blur!", editor);
    },

    beforeUpload() {
      // 显示loading动画
      this.getQiniuToken();
      this.quillUpdateImg = true;
    },
    uploadError() {
      this.quillUpdateImg = false;
      this.$message.error("图片插入失败");
    },
    goBackPage() {
      this.$router.push({
        name: "news",
        query: { page: this.$route.query.page || 1 },
      });
    },
    //富文本插入网络图片
    onLinkImageUrl() {
      var imageurl = document.querySelector(".url-image-fuzhu input").value;
      let quill = this.$refs.myTextEditor.quill;
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "image", imageurl);
      quill.setSelection(length + 1);
    },

    onSubmitInfo() {
      this.$refs["infoForm"].validate((valid) => {
        if (!valid) {         
              this.$message({
                type: "error",
                message: "信息未填写完整",
              });
              return false;
          }
          const loading = this.$loading({
            lock: true,
            text: "正在保存中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
          this.axios
            .post("news/store", {
              info: this.infoForm,
            })
            .then((response) => {
              loading.close();
              if (response.data.errno === 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.$router.go(-1);
              } else {
                this.$message({
                  type: "error",
                  message: "保存失败，请咨询管理员",
                });
              }
            });
      });
    },

    handleUploadListSuccess(res) {
      let url = this.url;
      this.infoForm.list_pic_url = url + res.key;
    },

    handleUploadDetailSuccess(res) {
      let url = this.url;
      let data = url + res.key + '=watershark';
      console.log('data==>',data);
      let quill = this.$refs.myTextEditor.quill;
      // 如果上传成功
      // 获取光标所在位置
      let length = quill.getSelection().index;
      // 插入图片  res.info为服务器返回的图片地址
      quill.insertEmbed(length, "image", data);
      // 调整光标到最后
      quill.setSelection(length + 1);
      console.log(quill);
      // this.$message.error('图片插入失败')
      // loading动画消失
      this.quillUpdateImg = false;
    },

    handleUploadImageSuccess(res, file) {
      if (res.errno === 0) {
        switch (res.data.name) {
          //商品图片
          case "goods_pic":
            this.infoForm.list_pic_url = res.data.fileUrl;
            break;
          case "goods_detail_pic":
            // res为图片服务器返回的数据
            // 获取富文本组件实例
            let quill = this.$refs.myTextEditor.quill;
            // 如果上传成功
            // 获取光标所在位置
            let length = quill.getSelection().index;
            // 插入图片  res.info为服务器返回的图片地址
            quill.insertEmbed(length, "image", res.data.fileUrl);
            // 调整光标到最后
            quill.setSelection(length + 1);
            // this.$message.error('图片插入失败')
            // loading动画消失
            this.quillUpdateImg = false;
            break;
        }
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    getInfo() {
      if (this.infoForm.id <= 0) {
        return false;
      }
      //加载商品详情
      let that = this;
      this.axios
        .get("news/info", {
          params: {
            id: that.infoForm.id,
          },
        })
        .then((response) => {
          let resInfo = response.data.data;
          that.infoForm = resInfo;
          that.getImgUrl();
        });
    },

    // summernote 上传图片，返回链接
    sendFile(file) {},
    // 初始化 summernote
    initSummerNote() {
      let that = this;
      $("#summernote").summernote({
        lang: "zh-CN",
        placeholder: "请输入商品描述",
        height: 300,
        minHeight: 300,
        maxHeight: 400,
        focus: true,
        // toolbar:[
        //   ['style',['bold','italic','clear']],
        //   ['fontsize',['fontsize']],
        //   ['para',['ul','ol','paragraph']],
        //   ['insert',['picture','link']]
        // ],
        callbacks: {
          onBlur: function (e) {
            console.log(" on blur ");
            console.log($("#summernote").summernote("code"));
            that.infoForm.content = $("#summernote").summernote("code");
            // that.infoForm.content = $('#summernote').summernote('code');
          },
          onImageUpload: function (files) {
            console.log("onImageUpLoad...");
            that.sendFile(files[0]);
          },
        },
      }),
        // console.error(that.infoForm.content);
        $("#summernote").summernote("code", that.infoForm.content);
    },
  },
  components: {
    ElFormItem,
    ElForm,
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    },
  },
  mounted() {
    this.infoForm.id = this.$route.query.id || 0;
    this.getInfo();
    this.getQiniuToken();
    this.root = api.rootUrl;
    this.qiniuZone = api.qiniu;
  },
};
</script>

<style lang="scss" scoped>
/* .edit_container{ */
/*.avatar-uploader .el-upload {*/
/*display: none;*/
/*}*/

.video-wrap {
  width: 300px;
}
.dialog-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.dialog-header .value {
  width: 150px;
  margin-right: 14px;
}

.input-wrap .el-input {
  width: 200px;
  float: left;
  margin: 0 20px 20px 0;
}

.input-wrap .el-input input {
  background-color: #fff !important;
  color: #233445 !important;
}

.specFormDialig .el-input {
  width: 150px;
  margin-right: 10px;
}

.el-select-class {
  width: 200px;
  margin-right: 20px;
}

.sepc-form {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.spec-form-wrap {
  margin-top: 0 !important;
}

.add-spec {
  margin-top: 10px;
}

.spec-form-wrap .header {
  display: flex;
  justify-content: flex-start;
}

.spec-form-wrap .header .l {
  width: 200px;
  margin-right: 20px;
}

.spec-form-wrap .header .m {
  width: 200px;
  margin-right: 20px;
}

.spec-form-wrap .header .m {
  width: 200px;
  margin-right: 20px;
}
.video-avatar {
  width: 148px;
  height: 148px;
}

/*.sepc-form div{*/
/*margin-left: 0;*/
/*}*/

.float-right {
  float: right;
}

.sepc-form .el-input {
  width: 200px;
  margin-right: 20px;
}

.marginTop20 {
  margin-top: 20px;
}

.checkbox-wrap .checkbox-list {
  float: left;
  margin-right: 20px;
}

.upload_ad {
  display: none;
}
.upload_ad .el-upload--picture-card {
  display: none;
}

.ql-container {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}

.image-uploader-diy {
  /*height: 200px;*/
  position: relative;
}

/*.dele-list-pic {*/
/*position: absolute;*/
/*left: 380px;*/
/*top: 0px;*/
/*}*/

.image-uploader-diy .el-upload {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image-uploader-diy .el-upload:hover {
  border-color: #20a0ff;
}

.image-uploader-diy .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.image-uploader-diy .image-show {
  min-width: 105px;
  height: 105px;
  display: block;
}

.image-uploader-diy .new-image-uploader {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader-diy .new-image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader-diy .new-image-uploader .image-show {
  width: 165px;
  height: 105px;
  display: block;
}

.item-url-image-fuzhu .el-input {
  width: 260px;
}

.hidden {
  display: none;
}
.spec-uploader /deep/ .el-upload {
  width: 50px;
  height: 50px;
  position: relative;
}
</style>
